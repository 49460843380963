import { GetStaticProps } from 'next';

import { Error } from '~templates';
import { ErrorProps, HttpStatusCode } from '~types';

export const getStaticProps: GetStaticProps<ErrorProps> = async () => {
  return { props: { code: HttpStatusCode.NotFound, description: 'страница не найдена' } };
};

export default Error;
